import React, {useState} from 'react';
import MaterialTable from '@material-table/core';
import { sharedStyles } from '../../shared/styles';
import UploadedByCell from './UploadedByCell';
import UploadedAtCell from './UploadedAtCell';
import UploadStatusCell from './UploadStatusCell';
import { useJobRoleUploadAttempts } from '../../queries';
import UploadDetailPanel from './UploadDetailPanel';
import Button from '@mui/material/Button';
import { saveBase64 } from '@a2d24/downloader/helpers';
import { downloadJobRoles, downloadJobUploadTemplate, downloadMedicalRoles } from '../../Apis/JobRole';
import { useSnackbar } from 'notistack';
import DownloadIcon from '@mui/icons-material/Download';

const JobRoleTable = ({ config, props }) => {
    const [downloading, setDownloading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const jobAttemptsQuery = useJobRoleUploadAttempts();
    const sharedClasses = sharedStyles();
    // TODO
    const jobRoles = []
    const medicalRoles = []
    //
    const clientLookUps = {};
    const clientJobRoleCounter = {};
    const medicalRoleLookups = {};
    jobRoles.forEach((jobRole) => {
        if(clientJobRoleCounter[jobRole.client]){
            clientJobRoleCounter[jobRole.client] += 1;
        }
        else{
            clientJobRoleCounter[jobRole.client] = 1;
        }
        clientLookUps[jobRole.client] = `${jobRole.client} (${clientJobRoleCounter[jobRole.client]})`;

    });
    medicalRoles.forEach((medicalRole) => {
        medicalRoleLookups[medicalRole] = medicalRole;
    });


    let columns = [
        {
            field: 'uploaded_by',
            title: 'Uploaded by',
            render: (rowData) => <UploadedByCell attempt={rowData} />,
            filtering: false,
        },
        {
            field: 'uploaded_at',
            title: 'Uploaded at',
            render: (rowData) => <UploadedAtCell attempt={rowData} />,
            filtering: true,
        },
        {
            field: 'status',
            title: 'Status',
            render: (rowData) => <UploadStatusCell attempt={rowData} />,
            filtering: true,
        },
    ];

     const handleJobRoleDownload = ()=>{
        if(downloading){
            return;
        }
        setDownloading(true);
        downloadJobRoles().then(
            (result)=>{
                saveBase64(result, 'text/csv', null, `job_roles_${new Date()}`);
            }
        ).catch(
            (error)=>{
                enqueueSnackbar('Failed to download Job Roles', { variant: 'error' });
            }
        ).finally(
            ()=>{
                setDownloading(false);
            }
        )
    }

     const handleMedicalRolesDownload = ()=>{
        if(downloading){
            return;
        }
        setDownloading(true);
        downloadMedicalRoles().then(
            (result)=>{
                saveBase64(result, 'text/csv', null, `medical_roles${new Date()}`);
            }
        ).catch(
            (error)=>{
                enqueueSnackbar('Failed to download Medical Roles', { variant: 'error' });
            }
        ).finally(
            ()=>{
                setDownloading(false);
            }
        )
    }

     const handleTemplateDownload = ()=>{
        if(downloading){
            return;
        }
        setDownloading(true);
        downloadJobUploadTemplate().then(
            (result)=>{
                saveBase64(result, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", null, `job_upload_template.xlsx`);
            }
        ).catch(
            (error)=>{
                enqueueSnackbar('Failed to download Job Upload Template', { variant: 'error' });
            }
        ).finally(
            ()=>{
                setDownloading(false);
            }
        )
    }



    return (
        <MaterialTable
            data={jobAttemptsQuery.data || []}
            columns={columns}
            enableExpandAll={false}

            components={
                {
                    Toolbar: () => {
                        return(
                            <div
                                style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingTop:'15px', paddingLeft: '10px', paddingRight: '30px'}}>
                                <div className={sharedClasses.subheading}>
                                    {config.title || props.title || 'Consults'}
                                </div>
                                <div style={{display: 'flex', gap: '10px', paddingRight: '50px'}}>
                                    <Button variant={'outlined'} size={'small'} onClick={handleTemplateDownload}> <DownloadIcon />Template</Button>
                                    <Button variant={'outlined'} size={'small'} onClick={handleJobRoleDownload}> <DownloadIcon /> Current Role Mapping</Button>
                                    <Button variant={'outlined'} size={'small'} onClick={handleMedicalRolesDownload}> <DownloadIcon /> All Medical Roles</Button>
                                </div>
                            </div>
                        )
                    }
                }
            }
            options={{
                // toolbar: true,
                headerStyle: {
                    color: '#999999',
                    fontSize: '14px',
                    fontWeight: 600,
                    lineHeight: '15px',
                    paddingRight: '15px',
                    textTransform: 'uppercase',
                },
                filterCellStyle: {
                    paddingBottom: '0px',
                    paddingTop: '0px',
                },
                search: false,
            }}
            detailPanel={
                ({rowData})=>{
                    return(
                        <UploadDetailPanel attempt={rowData}/>
                    )
                }
            }


        />
    );
};

export default JobRoleTable;
